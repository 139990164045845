import React, { useState, useEffect } from "react";
import { Routes, Route, Link, useNavigate, useLocation } from "react-router-dom";
import Axios from "axios";
import Home from "./Pages/Home";
import Parent from "./Pages/Parent";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Admin from "./Pages/Admin";
import List from "./Pages/List";
import Forgot from "./Pages/Forgot";
import ChangePass from "./Pages/ChangePass";

function App() {
  /**
   * Checks to see if the user is logged in
   */
  const [loginStatus, setLoginStatus] = useState(false);

  /**
   * Checks to see if the user is logged in
   */
  const [adminStatus, setAdminStatus] = useState(false);

  /**
   * Checks to see if the user attempted to log in
   */
  const [logAttempt, setLogAttempt] = useState(true);

  /**
   * The number of credits owned by user
   */
  const [credits, setCredits] = useState(0);

  /**
   * User ID on login
   */
  const [userID, setUserID] = useState(0);

  /**
   * Page title
  */
  const [pageTitle, setPageTitle] = useState("Easter Bunny's List - Check to see if you will get candy or rotten eggs!");

  /**
   * Set up objects
   */
  Axios.defaults.withCredentials = true;
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Handles authentication - Checks to see if user is logged in
   */
  const userAuthenticated = () => {
    Axios.get("/isUserAuth")
      .then((response) => {
        if (response.data.loggedIn) {
          // Check if admin
          if (response.data.admin == 1) {
            setAdminStatus(true);
          }
          setLoginStatus(true);
          setLogAttempt(true);
          setUserID(response.data.id);
        } else {
          setLoginStatus(false);
          setAdminStatus(false);
        }
      });
  }

  /**
   * Logs the user out
   */
  const logout = () => {
    Axios.post("/logout")
      .then((response) => {
        setLoginStatus(false);
        setLogAttempt(true);
        navigate("/");
      });
  }

  /**
   * Handles get credit request
  */
  const getCredits = (d) => {
    Axios.get("/credits")
      .then((response) => {
        setCredits(response.data.result[0].credits);
      });
  }

  /**
   * On page load
  */
  useEffect(() => {
    // Keep user session active
    userAuthenticated();

    // Load credits
    getCredits();
  }, []);

  /**
   * On page change
  */
  useEffect(() => {
    // Check if page title needs to be reset
    if (location.pathname != "/goodegg/" && location.pathname != "/badegg/") {
      setPageTitle("Easter Bunny\'s List - Check to see if you will get candy or rotten eggs!");
    }
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="App">
      <div className="container-fluid">
        <div className="container main_table">
          <div className="row">
            <div className="col-md-12">
              <header>
                <div className="logo">
                  <a href="/"><img src="/images/logo.png" alt="Logo" className="img-fluid" /></a>
                </div>
              </header>
            </div>
            <div className="col-md-12">
              <div className="main_table_content">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/parent" element={<Parent loginStatus={loginStatus} userID={userID} credits={credits} setCredits={setCredits} getCredits={getCredits} />} />
                  <Route path="/login" element={<Login navigate={() => navigate("/parent")} loginStatus={loginStatus} logAttempt={logAttempt} setLogAttempt={setLogAttempt} setLoginStatus={setLoginStatus} setUserID={setUserID} setAdminStatus={setAdminStatus} />} />
                  <Route path="/register" element={<Register loginStatus={loginStatus} />} />
                  <Route path="/admin" element={<Admin loginStatus={loginStatus} />} />
                  <Route path="/badegg" element={<List status="0" pageTitle={pageTitle} setPageTitle={setPageTitle} />} />
                  <Route path="/goodegg" element={<List status="1" pageTitle={pageTitle} setPageTitle={setPageTitle} />} />
                  <Route path="/forgot" element={<Forgot loginStatus={loginStatus} />} />
                  <Route path="/changepass" element={<ChangePass loginStatus={loginStatus} />} />
                </Routes>

                {loginStatus && location.pathname != "/" && location.pathname != "/nice/" && location.pathname != "/naughty/" && (
                  <div>
                    <hr />
                    <ul className="bottom-list">
                      {location.pathname != "/parent/" && location.pathname != "/parent" ? <li><Link to="parent/">Parent Home</Link></li> : ''}
                      {location.pathname != "/admin/" && adminStatus ? <li><Link to="admin/">Admin Panel</Link></li> : ''}
                      {location.pathname != "/changepass/" ? <li><Link to="changepass/">Change Password</Link></li> : ''}
                      <li><Link to="#/" onClick={logout}>Logout</Link></li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="parent-box">
                <p>Check out Santa's Website <a href="https://santashub.com">here.</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img src="/images/bunny.gif" height="150" alt="Easter Bunny" className="follow" id="bunny_follow" />
    </div>
  );
}

export default App;