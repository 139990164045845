import { Routes, Route, Link } from "react-router-dom";

export default function Home() {
  return (
    <div>
      <div className="main_text">
        <h1>Which list are you on?</h1>
        <p id="mobile">
          Check each list to find your name. Remember to come back and check to see if your name was moved to another list. We only give goodies to the good boys and girls of the world.
        </p>
      </div>
      <div className="row">
        <div className="col-md-4 col-6">
          <div className="egg-boxes">
            <a href="goodegg/">
              <img src="images/nice_egg.png" alt="Good Egg" className="img-fluid" />
              Good Egg
            </a>
          </div>
        </div>
        <div className="col-md-4 d-none d-md-block">
          <p>
            Check each list to find your name. Remember to come back and check to see if your name was moved to another list. We only give goodies to the good boys and girls of the world.
          </p>
        </div>
        <div className="col-md-4 col-6">
          <div className="egg-boxes">
            <a href="badegg/">
              <img src="images/rotten_egg.png" alt="Good Egg" className="img-fluid" />
              Bad Egg
            </a>
          </div>
        </div>
      </div>
      <div className="parent-message">
        <a href="parent/">Parents, click here to tell the Easter Bunny to add your children to the list!</a>
      </div>
      <div className="video">
        <div>
          <iframe width="75%" height="300" src="https://www.youtube.com/embed/iSsBrZhPnNw?rel=0&controls=0&amp;showinfo=0" frameBorder="0" allowFullScreen></iframe>
          <br />
          A message from the Easter Bunny.</div>
        <p>Having trouble finding your name on the list? With so many kids in the world, the bunnies are having a hard time keeping the list up-to-date. Tell your parents to visit this website and the Easter Bunny's Web Division will be right on it!</p>
      </div>
    </div>
  );
}