import React, { useState } from 'react';
import Axios from "axios";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

export default function Register(props) {
  const { register, handleSubmit, watch, setError, formState: { errors } } = useForm();

  const [accountCreated, setAccountCreated] = useState(false);

  const [formValue, setFormValue] = useState({
    email: '',
    password1: '',
    password2: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormValue((prevalue) => {
      return {
        ...prevalue,
        [name]: value
      }
    });
  };

  const onSubmit = (d) => {
    Axios.post("/register", {
      email: formValue.email,
      password: formValue.password1,
    }).then((response) => {
      setAccountCreated(true);
    });
  }

  const checkEmailExist = () => {
    Axios.get("/checkemail", {
      params: {
        email: formValue.email,
      }
    }).then((response) => {
      if (response.data.result) {
        setError('email', { type: 'custom', message: 'E-mail address already taken!' });
      } else {
        onSubmit();
      }
    });
  }

  const checkForm = () => {
    checkEmailExist();
  }

  if (!props.loginStatus && !accountCreated) {
    return (
      <div>
        <img src="/images/bunny_list.png" alt="Bunny with a list." className="side-images" />

        <form method="post" onSubmit={handleSubmit(checkForm)}>
          <div className="input-box">
            <b>E-mail:</b><br /><small><i>Must be a valid e-mail address.</i></small><br />
            <input
              type="text"
              maxLength="320"
              {...register("email", {
                required: "Please enter an e-mail address.",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Enter a valid e-mail address."
                },
                validate: () => {
                  Axios.get("/checkemail", {
                    params: {
                      email: formValue.email,
                    }
                  }).then((response) => {
                    if(response.data.result) {
                      setError('email', { type: 'custom', message: 'E-mail address already taken!' });
                    }
                  });
                }
              })}
              onChange={handleChange}
            />

            {errors.email && <span role="form-error">{errors.email.message}</span>}
          </div>

          <div className="input-box">
            <b>Password:</b>
            <br />
            <small><i>Must be at least 6 characters long.</i></small>
            <br />
            <input
              {...register("password1", {
                required: "Please enter a password.",
                minLength: {
                  value: 6,
                  message: "A password must be specified and be at least six characters."
                }
              })}
              type="password" maxLength="32" onChange={handleChange}
            />

            {errors.password1 && <span role="form-error">{errors.password1.message}</span>}
          </div>

          <div className="input-box">
            <b>Password (Confirm):</b>
            <br />
            <small><i>Must match first password field.</i></small>
            <br />
            <input
              {...register("password2", {
                required: "Please confirm your password.",
                validate: (val) => {
                  if (watch('password1') != val) {
                    return "Your passwords do not match.";
                  }
                }
              })}
              type="password"
              maxLength="32"
              onChange={handleChange}
            />

            {errors.password2 && <span role="form-error">{errors.password2.message}</span>}
          </div>

          <div className="input-box">
            <button type="submit">Sign Up!</button>
          </div>
        </form>
      </div>
    )
  } else if (accountCreated) {
    return (
      <div>
        <p className="center-content">
          Account created! <Link to="/login/">Click here to login.</Link>
        </p>
      </div>
    )
  } else {
    return (
      <div>
        <p className="center-content">
          You are logged in.
        </p>
      </div>
    )
  }
}