import { Link } from "react-router-dom";
import AddChildren from "../Modules/AddChildren";
import UpdateChildren from "../Modules/UpdateChildren";

export default function Parent(props) {
  if(!props.loginStatus) {
    return (
      <div>
        <Link to="/login">
          <img src="/images/login.png" border="0" className="img-fluid" />
        </Link>

        &nbsp;<b>OR</b>&nbsp;

        <Link to="/register">
          <img src="/images/register.png" border="0" className="img-fluid" />
        </Link>

        <p>
          <b>Click an option above.</b>
          To help the Easter Bunny add your children to the list, you first must create an account. If you do not have an account, click "Sign Up!" above.
        </p>
        
        <p>
          <iframe width="70%" height="315" src="https://youtube.com/embed/r8oBnuMED9o?rel=0&autoplay=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allowFullScreen></iframe>
        </p>
      </div>
    );
  } else {
    return (
      <div>
        <p>
          Welcome to the Easter Bunny's List! If you have any questions, comments, or concerns while using the naughty and nice list, please feel free to contact us.
        </p>

        <p className="icon-area">
						<a href="mailto:support@drennansoftware.com"><img src="/images/mail.png" alt="Send E-mail!" /></a>
						<a href="https://www.facebook.com/easterbunnyofficial" target="_blank"><img src="/images/facebook.png" alt="Facebook" /></a>
        </p>

        <hr />

        <AddChildren loginStatus={props.loginStatus} userID={props.userID} credits={props.credits} setCredits={props.setCredits} getCredits={props.getCredits} />

        <UpdateChildren loginStatus={props.loginStatus} credits={props.credits} setCredits={props.setCredits} getCredits={props.getCredits} />
      </div>
    );
  }
}